@import "~bootstrap/scss/bootstrap";
@import "theme/variables";
@import "theme/general";
@import "theme/generic";
@import "theme/card";
@import "theme/loader";
@import "theme/header";
@import "theme/sidebar";
@import "theme/grid";
@import "theme/errorpage";
@import "theme/icons";
@import "theme/alert";
@import "theme/popover";
@import "theme/list";
@import "theme/switch";
@import "theme/megaoption";
@import "theme/touchspin";
@import "theme/user-profile";
@import "theme/progress";
@import "theme/according";
@import "theme/login";
@import "theme/page";
@import "theme/pricing";
@import "theme/builders";
@import "theme/navs";
@import "theme/dropdown";
@import "theme/footer";
@import "theme/badge";
@import "theme/faq";
@import "theme/document";
@import "theme/internationalization";
@import "theme/box-layout";
@import "theme/responsive";

.cursor-pointer {
	cursor: pointer;
}
.shadow-box {
	-moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}
hr.horizontal-line {
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
}
a.blue-box {
	color: #ffffff;
	text-decoration: underline;
	white-space: nowrap;
}
a.blue-box-light {
	color: #353535;
	text-decoration: underline;
	white-space: nowrap;
}
.equal-height-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}
.equal-height-row > [class*='col-'] {
	display: flex;
	flex-direction: column;
}
.card-shadow {
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
}
.verified-control {
	width: 100%;
	margin-right: 5px;
}
.verified-container {
	display: inline-flex;
	align-items: center;
	width: 100%;
}
.bg-info-card {
	background-color: #71a6d2;
	color: #000000;
}
// customize angular archwizard
$aw-big-circle-width: 35px;
$aw-big-circle-height: 35px;
aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label {
  font-size: 12px !important;
  letter-spacing: -0.2px !important;
}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator i {
  font-size: 25px;
  position: relative;
  top: 7px;
  color: #063861;
}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.editing .step-indicator {
  background-color: #909090;
  color: black;
}

$defaultProLexColor: #3b5998;

.feature-btn {
	border-radius: 4px;
	background-color: $defaultProLexColor;
	//box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.1);
  box-shadow: 1px 1px 8px rgba(0,0,0,0.1);
	display: inline-block;
	vertical-align: middle;
	color: white !important;
	font-size: 20px;
	font-weight: 500;
	padding: 10px 40px;
	transition: all 0.4s ease;
	cursor: pointer;
	&:hover {
		background-color: #273167;;
		color: #fff;
	}
}
.gray-button {
  background-color: #dfdfdf;
}
/* progress button loading circle */
.dm-progress-button.e-progress-active .e-btn-content {
  display: none;
}
.dm-progress-button {
  transition: all .3s cubic-bezier(.175, .885, .32, 1) 100ms;
}
.sk-fading-circle-round-button {
	margin-left: -6px;
}
.sk-fading-circle {
  width: 20px;
  height: 20px;
	position: relative;
	.sk-circle {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		&:before {
			content: '';
			display: block;
			margin: 0 auto;
			width: 15%;
			height: 15%;
			background-color: #ffffff;
			border-radius: 100%;
			-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
							animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
		}
	}
	.sk-circle2 {
		-webkit-transform: rotate(30deg);
				-ms-transform: rotate(30deg);
						transform: rotate(30deg);
	}
	.sk-circle3 {
		-webkit-transform: rotate(60deg);
				-ms-transform: rotate(60deg);
						transform: rotate(60deg);
	}
	.sk-circle4 {
		-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
						transform: rotate(90deg);
	}
	.sk-circle5 {
		-webkit-transform: rotate(120deg);
				-ms-transform: rotate(120deg);
						transform: rotate(120deg);
	}
	.sk-circle6 {
		-webkit-transform: rotate(150deg);
				-ms-transform: rotate(150deg);
						transform: rotate(150deg);
	}
	.sk-circle7 {
		-webkit-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
						transform: rotate(180deg);
	}
	.sk-circle8 {
		-webkit-transform: rotate(210deg);
				-ms-transform: rotate(210deg);
						transform: rotate(210deg);
	}
	.sk-circle9 {
		-webkit-transform: rotate(240deg);
				-ms-transform: rotate(240deg);
						transform: rotate(240deg);
	}
	.sk-circle10 {
		-webkit-transform: rotate(270deg);
				-ms-transform: rotate(270deg);
						transform: rotate(270deg);
	}
	.sk-circle11 {
		-webkit-transform: rotate(300deg);
				-ms-transform: rotate(300deg);
						transform: rotate(300deg);
	}
	.sk-circle12 {
		-webkit-transform: rotate(330deg);
				-ms-transform: rotate(330deg);
						transform: rotate(330deg);
	}
	.sk-circle2:before {
		-webkit-animation-delay: -1.1s;
						animation-delay: -1.1s;
	}
	.sk-circle3:before {
		-webkit-animation-delay: -1s;
						animation-delay: -1s;
	}
	.sk-circle4:before {
		-webkit-animation-delay: -0.9s;
						animation-delay: -0.9s;
	}
	.sk-circle5:before {
		-webkit-animation-delay: -0.8s;
						animation-delay: -0.8s;
	}
	.sk-circle6:before {
		-webkit-animation-delay: -0.7s;
						animation-delay: -0.7s;
	}
	.sk-circle7:before {
		-webkit-animation-delay: -0.6s;
						animation-delay: -0.6s;
	}
	.sk-circle8:before {
		-webkit-animation-delay: -0.5s;
						animation-delay: -0.5s;
	}
	.sk-circle9:before {
		-webkit-animation-delay: -0.4s;
						animation-delay: -0.4s;
	}
	.sk-circle10:before {
		-webkit-animation-delay: -0.3s;
						animation-delay: -0.3s;
	}
	.sk-circle11:before {
		-webkit-animation-delay: -0.2s;
						animation-delay: -0.2s;
	}
	.sk-circle12:before {
		-webkit-animation-delay: -0.1s;
						animation-delay: -0.1s;
	}
}
@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
// control classes
.name-control {
  input {
    text-transform: capitalize;
  }
}
.uppercase-control {
	input {
		text-transform: uppercase;
	}
}
.error-msg {
  color: #ca2a27;
}
span.e-icon-dlg-close {
  right: 9px;
  position: relative;
  top: 1px;
}
.ps > .ps__rail-x, .ps > .ps__rail-y { opacity: 0.6; }
.fakeLink {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
/* Material theme fixes */
.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0 !important;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 0 !important;
}
.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
	line-height: 53px !important;
}
.e-edit-dialog .e-gridform .no-border-spacing .e-table {
	border-spacing: 0 !important;
}
.e-btn .e-btn-icon, .e-css.e-btn .e-btn-icon {
	font-size: 18px !important;
}
.e-btn, .e-css.e-btn {
	padding: 0.375rem 0.5rem !important;
}
.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
	padding-left: 6px;
}
.e-toolbar {
	min-height: 46px !important;
}
.e-control.e-grid .e-frozenheader.e-frozen-right-header > .e-table, .e-control.e-grid .e-frozencontent.e-frozen-right-content > .e-table {
	border-left-color: transparent !important;
}
.e-control.e-grid .e-emptyrow:not(.e-editedbatchcell) {
	text-align: center;
}
/* End fixes */
.form-row-inline {
	display: flex;
  align-items: center;
}
.enabler-checkbox {
	min-width: 100px;
	width: 110px;
}
.enabled-numerictextbox {
	width: 200px;
}
.form-row-margin-right {
  margin-right: 10px;
}
.form-group label {
	line-height: 0.7rem;
}
.app-page-title {
	text-align: center;
	font-size: 24px;
	color: #4466f2;
}
a, button {
	text-decoration: none !important;
}
.form-group {
	margin-bottom: 10px;
}
.e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
	margin: -7px 5px -7px -7px;
}
.e-grid .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
	margin: -7px 5px -7px -7px;
}
.e-bigger {
	.e-rowcell.e-hide {
		height: 0 !important;
	}
	.e-grid .e-res-toolbar .e-toolbar-items {
		min-height: 55px !important;
	}
	.e-grid.e-row-responsive .e-gridcontent td.e-rowcell {
		white-space: break-spaces;
	}
	.e-phone-input .country-value {
		margin-top: 60%;
	}
	.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom, .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom {
		line-height: 70px !important;
	}
	&.e-dialog .e-dlg-header {
		margin-top: 7px;
	}
}
.e-input-group.e-bigger .e-input,
.e-input-group.e-bigger.e-control-wrapper .e-input,
.e-bigger .e-input-group .e-input,
.e-bigger .e-input-group.e-control-wrapper .e-input {
	font-size: 16px;
}
.e-edit-dialog-no-footer .e-footer-content {
	display: none;
}
//.e-float-input.e-input-focus:not(.e-error) input ~ label.e-float-text, .e-float-input.e-control-wrapper.e-input-focus:not(.e-error) input ~ label.e-float-text, .e-bigger .e-float-input.e-control-wrapper.e-input-focus:not(.e-error) input ~ label.e-float-text {
//	background-color: white;
//	&:before, &:after {
//		background-color: transparent;
//		padding-left: 2px;
//		padding-right: 2px;
//	}
//}

//.page-title-description {
//	text-align: left;
//	font-size: 24px;
//	font-weight: bold;
//	background-color: #2f3c4e;
//	color: #ffffff;
//	width: 100%;
//}
//.page-title {
//	text-align: center;
//	font-size: 24px;
//	font-weight: bold;
//	width: 100%;
//}
//.page-title-back-button {
//	font-size: 36px;
//	color: #ffffff;
//	background-color: #2f3c4e;
//	cursor: pointer;
//}
