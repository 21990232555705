/* spinner service */
.dm-spinner-service .e-spinner-pane {
  overflow: hidden;
  border-radius: 8px;
}
.dm-spinner-service .e-spinner-pane .e-spinner-inner {
  padding: 20px;
  background: #ffffff;
  box-shadow: 3px 3px 8px rgba(0,0,0,0.5);
  border-radius: 8px;
}
.dm-spinner-service .e-spinner-pane .e-spinner-inner .e-spin-label {
  color: #000000;
}
