/* dialog service */
.members-custom-dialog.e-dialog .e-footer-content {
  background-color: #f8f8f8;
}
.members-custom-dialog.e-dialog.e-control.e-popup, .members-custom-dialog.e-dialog.e-control.e-popup .e-dlg-header-content {
  background-color: #3b5998;
}
.members-custom-dialog.e-dialog.e-control.e-popup .e-dlg-header, .members-custom-dialog.e-dialog.e-control.e-popup .e-dlg-header-content .e-dlg-header {
  color: #ffffff;
  text-align: center;
  width: 100% !important;
  font-size: 20px;
}
.members-custom-dialog.members-warning-dialog.e-dialog.e-control.e-popup, .members-custom-dialog.members-warning-dialog.e-dialog.e-control.e-popup .e-dlg-header-content {
  background-color: #8d8d08;
}
.members-custom-dialog.members-error-dialog.e-dialog.e-control.e-popup, .members-custom-dialog.members-error-dialog.e-dialog.e-control.e-popup .e-dlg-header-content {
  background-color: #7c1010;
}
.members-custom-dialog.e-dialog.e-control.e-popup {
  padding:3px;
}
.members-custom-dialog.e-dialog.e-control .e-dlg-header-content {
  padding: 10px;
}
.members-custom-dialog.e-dialog.e-control .e-footer-content {
  padding: 8px 12px;
}
.members-custom-dialog.e-dialog.e-control .e-dlg-content {
  padding: 15px;
  font-size: 16px;
  min-height: 100px;
  max-height: calc(100% - 100px);
  overflow: auto;
  overflow-x: hidden;
}
.members-custom-dialog.e-dialog.e-control .e-dlg-content .content-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
}
.members-custom-dialog.e-dialog.e-control .e-dlg-content i {
  font-size: 80px;
  color: #3b5998;
}
.members-custom-dialog.e-dialog.e-control .e-dlg-content .content-text {
  padding: 10px;
  width: 100%;
  text-align: justify;
}
.members-custom-dialog.members-warning-dialog.e-dialog.e-control .e-dlg-content i {
  color: #8d8d08;
}
.members-custom-dialog.members-error-dialog.e-dialog.e-control .e-dlg-content i {
  color: #7c1010;
}
